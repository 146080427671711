
import Vue from "vue";
import { isEmpty, isNull, isUndefined } from "lodash";
import { Notification, MessageTypes } from "../../../../interfaces/proccess";
import Alertize from "../../../../components/Alertize.vue";
import { initModifier, initModifierTermDelivery } from "@/utils/initData";
import Overview from "./../overview.vue";
import { mapActions } from "vuex";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({
	name: "ModifierCreate",
	props: {
		showButtonsActions: {
			type: Boolean,
			default: function() {
				return true;
			}
		}
	},
	components: {
		Alertize,
		Overview
	},
	data: () => ({
		entity: initModifier(),
		term:{
			delivery: []
		},
		resources: {
			advertiserList: [],
			modifierTypeList: [],
			module: [],
			deliveryModifiersType: [],
			modifiersOptions: {}
		}
	}),
	created() {},
	async mounted() {
		this.setLoadingData(TypeLoading.loading)
		await this.onLoadResources();
		await this.setLoadingData();
	},
	computed: {
		getAdvertisers(): any[] {
			return this.resources.advertiserList;
		},

		getModifierTypes(): any[] {
			return this.resources.modifierTypeList;
		},

		getModules(): any[] {
			return this.resources.module;
		},

		getDeliveryModifiers(){
			return this.resources.deliveryModifiersType;
		},

		getEntity() {
			return this.entity;
		},

		getTermDelivery(){
			return this.term.delivery;
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),

		...mapActions("modifier", ["getModifiersOptions", "getDeliveryModifiersType"]),

		async onLoadResources() {

			await this.dispatchAdvertisers().then((response) => {
				this.resources.advertiserList = response;
			}).catch(Err =>{
				this.resources.advertiserList = [];
			});
			await this.dispatchModifierTypes().then((response) => {
				this.resources.modifierTypeList = response;
			}).catch(Err =>{
				this.resources.modifierTypeList = [];
			});
			await this.dispatchModules().then((response) => {
				this.resources.module = response;
			}).catch(Err =>{
				this.resources.module = [];
			});
			await this.getDeliveryModifiersType().then((response) => {
				this.resources.deliveryModifiersType = response;
			}).catch(Err =>{
				this.resources.deliveryModifiersType = [];
			});
			this.resources.modifiersOptions = await this.getModifiersOptions();

		},

		redirectTo() {
			this.setNotification({ title: "", message: "", type: "" });
			this.$router.push({ name: "ModifiersIndex" });
		},

		setNotification(notification: Notification) {
			return this.$store.dispatch("proccess/setNotification", {
				...notification,
				show: true
			});
		},

		async handleCreate(parameter: { entity: any; isBidModifier: Boolean, isDeliveryModifier: boolean }) {
			try {
				this.setLoadingData(TypeLoading.loading)
				const result = await this.dispatchCreateEntity(
					parameter.entity,
					parameter.isBidModifier,
					parameter.isDeliveryModifier
				);
				if (isUndefined(result) || isNull(result) || isEmpty(result)) {
					this.setNotification({
						title: this.$t("title-failed"),
						message: this.$t("failed"),
						btn_text: this.$t(MessageTypes.TRYAGAIN),
						type: MessageTypes.ERROR
					});
				} else {
					this.setNotification({
						title: this.$t("title-success"),
						message: this.$t("success"),
						btn_text: this.$t(MessageTypes.CONTINUE),
						type: MessageTypes.SUCCESS,
						to: "ModifiersIndex"
					});
				}
			} catch (error) {
				console.error("handleCreate", { error: error });
			}
			await this.setLoadingData();
		},

		handleCancel() {
			this.$router.push({ name: "ModifiersIndex" });
		},

		async dispatchAdvertisers() {
			return this.$store.dispatch("advertiser/list", {
				filters: { active: true },
				options: { sort: "name", order: "asc" }
			});
		},

		async dispatchModifierTypes() {
			return this.$store.dispatch("modifier/getModifierTypes", {});
		},

		async dispatchModules() {
			return this.$store.dispatch("modifier/getModulesList", {});
		},

		async dispatchCreateEntity(entity: any, isBidModifier: Boolean = false, isDeliveryModifier: Boolean = false) {
			return this.$store.dispatch("modifier/createModifier", {
				modifier: entity,
				isBidModifier,
				isDeliveryModifier
			});
		},

		async handleDeleteTerms(){
			this.term.delivery = [];
		}
	}
});
